import React from "react";

const Banner = ({ title}) => {
  return (
    <div className="bg-hero-pattern bg-cover">
      <div className="flex flex-col p-14">
        <h1 className="text-3xl">Welcome to</h1>
        <h1 className="text-4xl font-bold">{title}</h1>
      </div>
    </div>
  );
};

export default Banner;
