import React, { useEffect, useState } from "react";
import axios from "axios";
// import { useNotifications } from "../NotificationContext";
// import SearchBar from "../SearchBar";

const TicketSidebar = ({ activeChatId, selectChat }) => {
  const [ticketUsers, setTicketUsers] = useState([]);
  const [searchTicket, setSearchTicket] = useState("");
  const [newMessages, setNewMessages] = useState({});

  const generateAvatarUrl = (seed) =>
    `https://api.dicebear.com/8.x/pixel-art/svg?seed=${seed}`;

  useEffect(() => {
    axios
      // .get(`http://localhost:4001/tickets/getUsersWithTickets`)
      .get(`https://backend.4xp.chat/tickets/getUsersWithTickets`)

      .then((response) => {
        setTicketUsers(response.data);
      })
      .catch((error) =>
        console.error("Failed to load users with tickets:", error)
      );
  });

  // const handleSearchTicket = (event) => {
  //   setSearchTicket(event.target.value);
  // };

  const handleSelectChat = (chat) => {
    selectChat(chat);
    // Mark the chat as read by removing it from the newMessages state
    setNewMessages((prevNewMessages) => ({
      ...prevNewMessages,
      [chat.id]: false,
    }));
  };

  const filteredTicketsUsers = ticketUsers.filter(
    (ticketUser) =>
      ticketUser.email &&
      ticketUser.email.toLowerCase().includes(searchTicket.toLowerCase())
  );

  return (
    <div className="w-64 max-sm:w-32 bg-gray-100 border-r border-gray-300 h-full flex flex-col ">
      <header className="p-4 border-b border-gray-300 bg-header-gradient text-white rounded-lg">
        <h1 className="text-2xl font-semibold">Tickets</h1>
      </header>
      <div>
        {/* <h2 className="text-xl font-semibold mb-2">Tickets</h2> */}
        {/* <SearchBar
          searchQuery={searchTicket}
          handleSearchQuery={handleSearchTicket}
          placeholder="Search Ticket"
        /> */}
        <div className="p-3 mt-4 overflow-y-auto h-64">
          {filteredTicketsUsers.length > 0 ? (
            filteredTicketsUsers.map((user) => (
              <div
                key={user.email}
                onClick={() =>
                  handleSelectChat({
                    type: "ticket",
                    id: user.email,
                    name: user.email,
                  })
                }
                className={`flex items-center mb-4 cursor-pointer hover:bg-gray-200 hover:text-black p-2 rounded-md ${
                  activeChatId === user.email ? "bg-gray-300 text-black" : ""
                }`}
              >
                <div className="w-12 h-12 max-sm:hidden bg-header-gradient rounded-full mr-3 flex items-center justify-center text-xl font-semibold">
                  {user.email.charAt(0).toUpperCase()}
                </div>
                <div className="flex-1">
                  <h2 className="text-lg font-semibold">{user.email.charAt(0).toUpperCase()+ user.email.slice(1).split("@")[0]}</h2>
                  <p className="text-gray-600">View Tickets</p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600 mt-4">No tickets available</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketSidebar;
