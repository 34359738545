import React from 'react'

const SearchBar = () => {
  return (
    <div className='flex flex-col items-center justify-center'>
        {/* SearchBar */}
        <p className='text-3xl p-2'>Hi Admin, we’re here to help.</p>
        <input className='p-2 m-2 bg-gray-300 rounded-lg w-[70%]' type="text" placeholder="How can we Help?" />
    </div>
  )
}

export default SearchBar