import io from "socket.io-client";

// const SOCKET_URL = "http://localhost:4001";
const SOCKET_URL = "https://backend.4xp.chat";
const adminId = localStorage.getItem("adminId");
const adminType = 'admin'; // 'admin' or 'superadmin'

export const socket = io(SOCKET_URL, {
  reconnectionAttempts: 5,
  timeout: 10000,
});

export const initiateSocket = () => {
  socket.on("connect", () => {
    console.log("Connected to socket server");
    socket.emit("register", { userId: adminId, userType: adminType });
  });

  socket.on("disconnect", () => {
    console.log("Disconnected from socket server");
  });

  socket.on("connect_error", (error) => {
    console.error("Connection error:", error);
  });

  socket.on("reconnect_attempt", () => {
    console.log("Reconnecting.....");
  });
};