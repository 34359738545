import React from "react";
import Banner from "../components/Dashboard/Banner";
import Cards from "../components/Dashboard/Cards";
import chatIconCard from "../assets/chatIconCard.png";
import ticketIconCard from "../assets/ticketc.svg"
import graph4 from "../assets/graph4.png";
import graph from "../assets/graph.png";

const Dashboard = () => {
  return (
    <div className="overflow-y-auto">
      <h1 className="text-3xl mb-3">Admin Portal</h1>
      <Banner />
      <div className="flex space-x-5 max-sm:flex-col max-sm:items-center">
        <Cards
          chatIconCard={chatIconCard}
          graph4={graph4}
          name="Live Chat"
          totalChats="00005"
          color="bg-card-gradient"
        />
        <Cards
          chatIconCard={ticketIconCard}
          graph4={graph}
          name="Total Tickets"
          totalChats="00010"
          color="bg-red-card-gradient"
        />
      </div>
    </div>
  );
};

export default Dashboard;
