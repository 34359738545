import React from "react";

const Cards = ({ chatIconCard, name, graph4, totalChats , color }) => {
  return (
    <div>
      <div className={`flex flex-col w-[350px] h-[220px] ${color} mt-5 ml-5 rounded-xl`}>
        <div className="flex flex-row items-center mt-14">
          <img src={chatIconCard} alt="chatIconCard" className="w-16 h-16"/>
          <div className="flex flex-col">
            <p className="ml-2 text-2xl font-bold">{name}</p>
            <p className="ml-2">{totalChats}</p>
          </div>
        </div>
        <div className="flex items-center">
          <img src={graph4} alt="graph4" />
        </div>
      </div>
    </div>
  );
};

export default Cards;
