import React from 'react';
import { NavLink } from 'react-router-dom';
import dashboardIcon from '../assets/dashboardb.png';
import chatIcon from '../assets/chat.png';
import ticketIcon from '../assets/ticketc.svg';
import livechat from '../assets/livechatc.svg';
import knowledgebase from '../assets/knowledgebase.png';

const Sidebar = ({ isOpen }) => {
  return (
    <div className={`fixed bg-white inset-y-0 left-0 transform ${isOpen ? 'translate-x-0' : '-translate-x-full'} 
    transition-transform duration-300 ease-in-out bg-[#2532d6] 
    sm:relative sm:translate-x-0 w-64 sm:w-20 p-4 text-black flex flex-col 
    border-r-2 border-solid border-gray-400 h-screen overflow-y-auto
    ${isOpen ? 'z-50' : 'z-30'}`}>
      <div className="flex flex-col space-y-6">
        <NavLink to="/home/dashboard" className='flex items-center hover:bg-blue-700 p-2 rounded-lg'>
          <img src={dashboardIcon} alt="Dashboard" className='w-6 sm:w-8'/>
          <span className='ml-3 sm:hidden'>Dashboard</span>
        </NavLink>
        <NavLink to="/home/LiveChat" className='flex items-center hover:bg-blue-700 p-2 rounded-lg'>
          <img src={livechat} alt="Live Chat" className='w-6 sm:w-8'/>
          <span className='ml-3 sm:hidden'>Live Chat</span>
        </NavLink>
        <NavLink to="/home/ticket" className='flex items-center hover:bg-blue-700 p-2 rounded-lg'>
          <img src={ticketIcon} alt="Ticket" className='w-6 sm:w-8'/>
          <span className='ml-3 sm:hidden'>Ticket</span>
        </NavLink>
        <NavLink to="/home/knowledgebase" className='flex items-center hover:bg-blue-700 p-2 rounded-lg'>
          <img src={knowledgebase} alt="Knowledge Base" className='w-6 sm:w-8'/>
          <span className='ml-3 sm:hidden'>Knowledge Base</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Sidebar;
