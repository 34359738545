import React from 'react'
import { NavLink } from 'react-router-dom';

const Subheader = () => {
  return (
    <div className='flex justify-center'>
        <NavLink to="/home/LiveChat">
          <button className='p-1 m-2 bg-gray-300 rounded-lg hover:bg-blue-700 hover:text-white'>Live Chat</button>
        </NavLink>
        <NavLink to="/home/ticket">
           <button className='p-1 m-2 bg-gray-300 rounded-lg hover:bg-blue-700 hover:text-white'>Ticket</button>
        </NavLink> 
    </div>
  )
}

export default Subheader;