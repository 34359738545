import React, { useEffect, useState } from "react";
import axios from "axios";

const Faqs = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    content: "",
  });
  const [faqs,setFaqs] = useState([]);

  useEffect (()=>{
    const getFaq = async()=>{
    try{
      // const response = await axios.get("http://localhost:4001/faq/getFaq");
      const response = await axios.get("https://backend.4xp.chat/faq/getFaq");
      console.log(response.data);
      setFaqs(response.data);
    } catch (error) {
      console.error("Error fetching FAQs:", error.response ? error.response.data : error.message);
    }
  };
  getFaq();
  }, [])

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // const response = await axios.post("http://localhost:4001/faq/insertFaq", formData);
      const response = await axios.post("https://backend.4xp.chat/faq/insertFaq", formData);
      console.log(response.data);
      setFormData({
        title: "",
        content: "",
      });

    } catch (error) {
      console.error("Error submitting FAQ:", error.response ? error.response.data : error.message);
    }
  };

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <div className="max-w-screen-xl mx-auto px-5 bg-white min-h-sceen">
      <div className="flex flex-col items-center">
        <h2 className="font-bold text-5xl mt-5 tracking-tight">FAQ</h2>
        <p className="text-neutral-500 text-xl mt-3">
          Frequenty asked questions
        </p>
      </div>
      <div className="flex justify-center">
        <button
          onClick={toggleModal}
          className="p-2 m-4 bg-gray-300 rounded-lg bg-header-gradient hover:bg-blue-700 hover:text-white"
        >
          Add Faq
        </button>
      </div>
      {isModalOpen && (
        <>
          <div
            className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                      <h3
                        className=" text-2xl font-semibold leading-6 text-gray-900"
                        id="modal-title"
                      >
                        Please Insert an FAQ
                      </h3>
                      <div className="mt-2">
                        <form
                          onSubmit={handleSubmit}
                          className="space-y-8 divide-y divide-gray-200"
                          action="#"
                        >
                          <div>
                            <label
                              htmlFor="title"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Title
                            </label>
                            <input
                              type="text"
                              name="title"
                              id="title"
                              value={formData.title}
                              onChange={handleChange}
                              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              placeholder="Enter Title"
                              required
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="content"
                              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                            >
                              Content
                            </label>
                            <textarea
                              type="textarea"
                              name="content"
                              id="content"
                              value={formData.content}
                              onChange={handleChange}
                              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                              required
                            />
                          </div>
                          <button type="submit"  className="mt-4 inline-flex justify-center rounded-md bg-blue-500 px-4 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-700">Submit</button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={toggleModal}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="grid divide-y divide-neutral-200 max-w-xl mx-auto mt-8">
        {faqs.map((faq,index)=>(  
        <div key={index} className="py-5">
          <details className="group">
            <summary className="flex justify-between items-center font-medium cursor-pointer list-none">
              <span>{faq.title}</span>
              <span className="transition group-open:rotate-180">
                <svg
                  fill="none"
                  height="24"
                  shape-rendering="geometricPrecision"
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="1.5"
                  viewBox="0 0 24 24"
                  width="24"
                >
                  <path d="M6 9l6 6 6-6"></path>
                </svg>
              </span>
            </summary>
            <p className="text-neutral-600 mt-3 group-open:animate-fadeIn">
              {faq.content}
            </p>
          </details>
        </div>
         ))}
      </div>
    </div>
  );
};

export default Faqs;
